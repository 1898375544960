 const tab = {

  load: function(){

    $('.more-tab').dropdown();

    let allWidth = $('.search-menu').width(),
        allVisibleElemWidth = 0,
        moreLinkWidth = $('.search-menu .last-item').width();

    $('.search-menu .tab-item').each(function(){
      allVisibleElemWidth += $(this).outerWidth(true);

      if(allWidth <= Math.round(moreLinkWidth + allVisibleElemWidth)){
        $(this).last().appendTo($('.select-filters-container .more-menu ul'))
      }
    });

    if($(".select-filters-container .more-menu").find('li').length == 0){
      $('.search-menu .more-tab').css('visibility', 'hidden');
    }else{
      $('.search-menu .more-tab').css('visibility', 'visible');
    }

    $('.more-menu').css({
      'right': $(document).width() - ($('.more-tab')?.offset()?.left + $('.more-tab').width()) - 16,
      'top': 30
    });
  },
  resize: function(){
    let allWidth = $('.search-menu').width(),
      allVisibleElemWidth = 0,
      moreLinkWidth = $('.search-menu .last-item').width(),
      freeSpace = 0;

    //decrease in size
    $('.search-menu .tab-item').each(function(){
      allVisibleElemWidth += $(this).outerWidth(true);

      freeSpace = allWidth - allVisibleElemWidth;

      if(allWidth < moreLinkWidth + allVisibleElemWidth){
        if($('.select-filters-container .more-menu .tab-item').length == 0){
          $('.search-menu .tab-item').last().appendTo($('.select-filters-container .more-menu ul'));
        }else{
          $('.search-menu .tab-item').last().prependTo($('.select-filters-container .more-menu ul'))
        }
      }

    });

    //increase in size
    $('.select-filters-container .more-menu .tab-item').each(function(i, val){

      var elem = $(this).find('a span');
      var getFirstElemFromMoreMenu = $(".select-filters-container .more-menu li:first-child");

      if(getFirstElemFromMoreMenu.length == 0) return false;

      //16 is padding from visible menu, 8 is margin
      allVisibleElemWidth += elem.width() + 16 + 8;
      freeSpace = allWidth - allVisibleElemWidth;

      if(allWidth >= moreLinkWidth + allVisibleElemWidth){
        getFirstElemFromMoreMenu.insertAfter($('.search-menu .tab-item').last());
      }else{
        return false
      }
    });

    if($(".select-filters-container .more-menu").find('li').length == 0){
      $('.search-menu .more-tab').css('visibility', 'hidden');
      $('.more-menu-dropdown').attr('style', '');
    }else{
      $('.search-menu .more-tab').css('visibility', 'visible');
      $('.more-menu').css({
        'right': $(document).width() - ($('.more-tab')?.offset()?.left + $('.more-tab').width()) - 16,
        'top': 30
      });
    }
  }
}

export {
  tab
}

